.title {
  position: relative;
}
.titleName {
  color: #e30014;
}

.lowerCase {
  font-size: 12px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f0f0f0;
  padding: 4px;
  border-radius: 4px;
}
